import { PriceDomain, WeekDay, PriceDto } from '@wix/bookings-uou-domain';
import { Experiments } from '../../Shared/experiments/experiments';
import { getDefaultTimezone } from '../../Shared/utils';
import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';

export class OfferingDomain {
  private constructor(protected offeringDto: CatalogServiceDto) {}

  static fromDto(offeringDto: CatalogServiceDto) {
    return offeringDto ? new OfferingDomain(offeringDto) : null;
  }

  get name() {
    return this.offeringDto.info.name;
  }

  set name(newName: string) {
    this.offeringDto.info.name = newName;
  }

  get type() {
    return this.offeringDto.type;
  }

  get categoryId() {
    return this.offeringDto.categoryId;
  }

  get fullUrl() {
    return this.offeringDto.fullUrl;
  }

  get isPendingApprovalFlow() {
    return this.offeringDto.schedulePolicy.isPendingApprovalFlow;
  }

  priceText(
    locale,
    regionalSettingsLocale,
    isUseIntlForPriceInBookFlowEnabled,
  ) {
    const priceDto: PriceDto = {
      ...this.offeringDto.payment,
      displayTextForPlan: this.offeringDto.pricingPlanInfo
        ? this.offeringDto.pricingPlanInfo.displayText
        : '',
    };
    return new PriceDomain(
      priceDto,
      this.offeringDto.offeredAs,
      locale,
      regionalSettingsLocale,
      isUseIntlForPriceInBookFlowEnabled,
    ).text;
  }

  priceAriaLabelText(
    locale,
    regionalSettingsLocale,
    isUseIntlForPriceInBookFlowEnabled,
  ) {
    const priceDto: PriceDto = {
      ...this.offeringDto.payment,
      displayTextForPlan: this.offeringDto.pricingPlanInfo
        ? this.offeringDto.pricingPlanInfo.displayText
        : '',
    };
    return new PriceDomain(
      priceDto,
      this.offeringDto.offeredAs,
      locale,
      regionalSettingsLocale,
      isUseIntlForPriceInBookFlowEnabled,
    ).ariaLabel;
  }

  get durationText() {
    return this.offeringDto.scheduleHeader.durationText;
  }

  formattedStartDate(formatter, locale, businessInfo) {
    if (this.offeringDto.scheduleHeader.startDateAsUTC) {
      try {
        const startDate = new Date(
          this.offeringDto.scheduleHeader.startDateAsUTC,
        );

        const isDateInCurrentYear = (date) => {
          const now = new Date();
          return now.getFullYear() === date.getFullYear();
        };

        const isDateInThePast = (date) => {
          const now = new Date();
          return date.getTime() < now.getTime();
        };

        const timeZone = getDefaultTimezone(businessInfo);
        const dateFormat = isDateInCurrentYear(startDate)
          ? {
              month: 'short',
              day: 'numeric',
              timeZone,
            }
          : {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              timeZone,
            };

        const formattedStartDate = new Intl.DateTimeFormat(
          locale,
          dateFormat,
        ).format(startDate);

        const formattedDate = isDateInThePast(startDate)
          ? `${formatter(
              'service.schedule.course.start.date.passed',
            )} ${formattedStartDate}`
          : `${formatter(
              'service.schedule.course.start.date.future',
            )} ${formattedStartDate}`;

        return formattedDate;
      } catch (e) {
        return '';
      }
    }
    return '';
  }

  get pricingPlanInfo() {
    return this.offeringDto.pricingPlanInfo;
  }

  get scheduleHeader() {
    return this.offeringDto.scheduleHeader;
  }

  daysTextByFormat(formatter, experiments: Experiments = {}) {
    if (this.type === ServiceType.GROUP) {
      const daysOrder =
        formatter('first-day-of-week') === formatter('week-day.sun')
          ? [
              WeekDay.SUNDAY,
              WeekDay.MONDAY,
              WeekDay.TUESDAY,
              WeekDay.WEDNESDAY,
              WeekDay.THURSDAY,
              WeekDay.FRIDAY,
              WeekDay.SATURDAY,
            ]
          : [
              WeekDay.MONDAY,
              WeekDay.TUESDAY,
              WeekDay.WEDNESDAY,
              WeekDay.THURSDAY,
              WeekDay.FRIDAY,
              WeekDay.SATURDAY,
              WeekDay.SUNDAY,
            ];
      let offeringDays;
      if (this.offeringDto.scheduleHeader.daysDetails) {
        offeringDays = this.offeringDto.scheduleHeader.daysDetails
          .filter((dayDetails) => {
            if (this.shouldDisplayDay(dayDetails.expirationDate)) {
              return dayDetails;
            }
          })
          .map((dayDetails) => dayDetails.day);
      } else {
        offeringDays = this.offeringDto.scheduleHeader.days;
      }

      if (offeringDays.length === 7) {
        return formatter('working-hours.all-week');
      }
      const weekDayKeyPrefix: string =
        offeringDays.length <= 2 ? 'long-week-day.' : 'short-week-day.';
      return daysOrder
        .filter((day) => offeringDays.indexOf(day) > -1)
        .map((day) => formatter(weekDayKeyPrefix + day))
        .join(', ');
    }
  }

  shouldDisplayDay(expirationDate) {
    return (
      !expirationDate ||
      new Date(expirationDate).getTime() > new Date().getTime()
    );
  }

  errorMessageTranslation(formatter) {
    return formatter('tile-service.premium-not-enabled.message');
  }

  get image() {
    const image =
      this.offeringDto.info.images && this.offeringDto.info.images[0];
    return image ? image : null;
  }

  get isOfferedOnline() {
    return !!this.offeringDto.schedulePolicy?.conferenceProvider?.platform;
  }

  get displayOnlyNoBookFlow() {
    return this.offeringDto.schedulePolicy.displayOnlyNoBookFlow;
  }

  get description() {
    return this.offeringDto.info.description;
  }

  get tagLine() {
    return this.offeringDto.info.tagLine;
  }

  get payment() {
    return this.offeringDto.payment;
  }

  set currency(newCurrency) {
    this.offeringDto.payment.currency = newCurrency;
  }

  get id() {
    return this.offeringDto.id;
  }

  get dummy() {
    return this.offeringDto.dummy;
  }

  get locations() {
    return this.offeringDto.info.locations;
  }
}
