import * as React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';
import { st, classes } from './MultiOfferingsTitle.st.css';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';
import { AlignmentOptions } from '../../../../Shared/appKeys/SettingsKeys';

interface MultiOfferingsTitleProps extends RunningEnvironmentProps {
  children?;
  alignment?: AlignmentOptions;
  htmlTag: string;
}

const MultiOfferingsTitleComponent = ({
  runningEnvironment,
  children,
  alignment,
  htmlTag,
}: MultiOfferingsTitleProps) => {
  const { isMobile } = runningEnvironment;
  return (
    <div
      className={st(classes.root, {
        alignment,
        disableFontSize: isMobile,
        mobile: isMobile,
      })}
    >
      <Text
        className={st(classes.title)}
        typography={TextTypography.largeTitle}
        data-hook="multi-offering-inner-title"
        tagName={htmlTag}
      >
        {children}
      </Text>
    </div>
  );
};

export const MultiOfferingsTitle = withRunningEnvironmentContext(
  MultiOfferingsTitleComponent,
);
