import * as React from 'react';
import {
  Button,
  ButtonPriority as PRIORITY,
  ButtonSize as SIZE,
} from 'wix-ui-tpa';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';
import { st, classes } from './OfferingCTA.st.css';
import { ButtonStyleOptions } from '../../../../Shared/appKeys/SettingsKeys';

export interface OfferingCTAProps extends RunningEnvironmentProps {
  large?: boolean;
  secondary?: boolean;
  theme?: ButtonStyleOptions;
  onClick?: Function;
  children?: any;
  dataHook?: string;
}

const OfferingCTAComponent = ({
  large,
  secondary,
  theme,
  onClick,
  children,
  runningEnvironment,
  dataHook,
}: OfferingCTAProps) => {
  const { isMobile } = runningEnvironment;
  return (
    <div className={classes.buttonWrapper}>
      <Button
        className={st(classes.root, {
          theme,
          isMobile,
          disableFontSize: isMobile,
        })}
        size={large ? SIZE.large : SIZE.medium}
        priority={secondary ? PRIORITY.secondary : PRIORITY.primary}
        onClick={onClick as any}
        fullWidth={isMobile}
        aria-label={children}
        role="button"
        tabIndex={0}
        data-hook={dataHook}
      >
        {children}
      </Button>
    </div>
  );
};

export const OfferingCTA = withRunningEnvironmentContext(OfferingCTAComponent);
