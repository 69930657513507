import * as React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';
import { st, classes } from './OfferingDetails.st.css';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

interface OfferingDetailsProps extends RunningEnvironmentProps {
  ellipsis?: boolean;
  children?;
  htmlTag?: string;
  ariaHidden?;
}

const OfferingDetailsComponent = ({
  runningEnvironment,
  ellipsis = false,
  children,
  htmlTag,
  ariaHidden = false,
}: OfferingDetailsProps) => {
  const { isMobile } = runningEnvironment;
  return (
    <div
      className={classes.detailsWrapper}
      {...{ 'aria-hidden': ariaHidden ? ariaHidden : undefined }}
    >
      <Text
        typography={TextTypography.listText}
        // tabIndex={-1}
        className={st(classes.root, {
          disableFontSize: isMobile,
          isMobile,
          ellipsis,
        })}
        tagName={htmlTag}
        data-hook="offering-details-title"
      >
        {children}
      </Text>
    </div>
  );
};

export const OfferingDetails = withRunningEnvironmentContext(
  OfferingDetailsComponent,
);
