import * as React from 'react';
import { Tabs, TabsSkin, TabsAlignment, TabsVariant } from 'wix-ui-tpa';
import { st, classes } from './TabsCategories.st.css';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../../context/running-environment-context';
import { CategoriesProps } from '../Categories';

interface TabsCategoriesProps
  extends RunningEnvironmentProps,
    CategoriesProps {}

class TabsCategoriesComponent extends React.PureComponent<TabsCategoriesProps> {
  constructor(props: TabsCategoriesProps) {
    super(props);
    this.onCategoryTabSelected = this.onCategoryTabSelected.bind(this);
  }

  onCategoryTabSelected(newSelectedCategoryIndex) {
    this.props.onCategorySelected(newSelectedCategoryIndex);
  }

  render() {
    const {
      categoriesViewModel,
      categoriesItems,
      selectedCategoryIndex,
      runningEnvironment,
    } = this.props;
    const { alignment, variant } = categoriesViewModel;
    const items = categoriesItems.map((category) => {
      return {
        title: category.title,
      };
    });
    const { isMobile } = runningEnvironment;

    return (
      <Tabs
        data-hook="offerings-categories"
        className={st(classes.root, { isMobile, disableFontSize: isMobile })}
        items={items}
        onTabClick={(newSelectedCategoryIndex) =>
          this.onCategoryTabSelected(newSelectedCategoryIndex)
        }
        activeTabIndex={selectedCategoryIndex}
        skin={TabsSkin.fitUnderline}
        alignment={(alignment as unknown) as TabsAlignment}
        variant={variant as TabsVariant}
      />
    );
  }
}

export const TabsCategories = withRunningEnvironmentContext(
  TabsCategoriesComponent,
);
