import * as React from 'react';
import { st, classes } from './OfferingTagLine.st.css';
import { Text } from 'wix-ui-tpa';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

interface OfferingTagLineProps extends RunningEnvironmentProps {
  children?;
  htmlTag: string;
}

const OfferingTagLineComponent = ({
  children,
  runningEnvironment,
  htmlTag,
}: OfferingTagLineProps) => {
  const { isMobile } = runningEnvironment;

  return (
    <div tabIndex={-1}>
      <Text
        // isMobile={isMobile}
        className={st(classes.root, { disableFontSize: isMobile, isMobile })}
        data-hook="offering-tag-line-title"
        tagName={htmlTag}
      >
        {children}
      </Text>
    </div>
  );
};

export const OfferingTagLine = withRunningEnvironmentContext(
  OfferingTagLineComponent,
);
